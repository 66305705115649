import React, { useEffect } from "react";
import { FLORA_SHOPIFY } from "../../assets/Links";

const Connect = () => {
  useEffect(() => {
    window.location.replace(`${FLORA_SHOPIFY}/pages/connect-your-flora-pod`);
  }, []);

  return <div></div>;
};

export default Connect;
